<template>
    <!--    <dv-full-screen-container  class="bg">-->
    <div>
        <div class="login-container">
            <div class="login-card">
                <dv-decoration-11 style="height:80px;text-align: center;">
                    <div class="login-title">阡陌易联生态农场</div>
                </dv-decoration-11>
                <dv-border-box-1 style="padding: 30px;margin-left: -20px">
                    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-width="40px"
                             class="login-form">
                        <el-form-item prop="username" style="margin-bottom: 40px">
                            <el-input v-model="loginForm.username" style="background: transparent"
                                      placeholder="请输入农场账号" autocomplete="off">
                        <span slot="prefix" class="el-icon-user-solid"
                              style="color: #4ECFDB;font-size: 28px;line-height: 50px">
                        </span>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"
                                      autocomplete="off">

                            <span slot="prefix" class="el-icon-lock"
                                  style="color: #4ECFDB;font-size: 28px;line-height: 50px"></span>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </dv-border-box-1>
                <dv-border-box-8 style="margin-top: 30px;height: 60px;line-height: 60px;margin-left: 10px">
                    <div class="login-btn" @click="login">登录</div>
                </dv-border-box-8>
            </div>
        </div>

        <el-dialog
                title="请选择农场"
                :visible.sync="dialogVisible"
                width="40%"
               >
            <div>
            <el-select v-model="farmId" placeholder="请选择"  style="width: 100%">
                <el-option
                        v-for="item in farmList"
                        :key="item.farmId"
                        :label="item.farmName"
                        :value="item.farmId">
                </el-option>
            </el-select>
            <dv-border-box-8 style="margin-top: 30px;height: 60px;line-height: 60px;">
                <div class="login-btn" @click="goToFarm">进入农场</div>
            </dv-border-box-8>
            </div>
        </el-dialog>
    </div>
    <!--    </dv-full-screen-container>-->
</template>

<script>
export default {
    data() {
        return {
            loginForm: {
                username: '',
                password: '',
            },
            dialogVisible: false,
            farmList: [],
            farmId: '',
        };
    },
    computed: {
        loginRules() {
            return {
                username: [
                    {required: true, message: '请输入农场账号', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                ],
            }

        },
    },
    methods: {
        login() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    // 在这里执行登录逻辑
                    // 登录成功后选择农场再跳转
                    this.$http.post('app/farm/user/login', this.loginForm).then(res => {
                        if(res.data.data) {
                            this.farmList = res.data.data.farmList;
                            this.$store.commit('setToken', res.data.data.token);
                            this.$store.commit('setFarmUser', res.data.data.farmUser);
                            this.dialogVisible = true;
                        }
                        else {
                            this.$message.error(res.data.msg);
                        }

                    }).catch((err) => {
                        console.log("而登录报错", err);
                        this.$message.error(err.data.message);
                    });

                } else {

                    this.$message.error('登录失败');
                    return false;
                }
            });
        },
        goToFarm() {

            if(this.farmId) {

                let farmInfo = this.farmList.find(item => item.farmId === this.farmId);
                this.$store.commit('setFarmInfo',farmInfo);
                this.$router.push({name: 'index', query: {}});
            }
            else {
                this.$message.error('请选择农场');
            }
        },
    },
};
</script>

<style lang="scss">
@import '../assets/scss/index.scss';

.login-bg {
    width: 100vw;
    height: 100vh;
    background-color: #020308;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../assets/pageBg.png");
    background-size: cover;
    background-position: center center;
}

.login-card {
    padding: 20px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-title {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #4ECFDB;
}

.login-form {
    margin-top: 40px;

}


.login-btn {
    color: #4ECFDB;
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;

    &:active {
        background: rgba(0, 235, 239, 0.3);
    }
}

.el-input--small .el-input__inner {
    background: transparent;
    color: #00ebef;
    font-size: 28px;
    height: 50px;
    border-color: rgba(0, 235, 239, 0.4);
}

.el-form-item__error {
    color: rgba(25, 139, 238, 0.4);
    font-size: 24px;
}

.el-input--prefix .el-input__inner {
    padding-left: 40px;
}

.el-input__inner::placeholder {
    color: rgba(0, 235, 239, 0.4);
    border-color: rgba(0, 235, 239, 0.4);
}


.el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus, .el-message-box__input input.invalid, .el-message-box__input input.invalid:focus {
    border-color: rgba(0, 235, 239, 0.4);
}
.el-dialog{
    background: #020308;
    border: #5cd9e8 2px solid;
    border-radius: 5px;
}
.el-dialog__title {
    color: #4ECFDB;
    font-size: 24px;
    font-weight: bold;
}
.el-dialog__headerbtn .el-dialog__close{
    color: #4ECFDB;
    font-size: 24px;
}
.el-dialog__body {
}
.el-dialog__header {
    color: #4ECFDB;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid #4ECFDB;
}
.el-select-dropdown{
    background: #020308;
    border: #5cd9e8 2px solid;
    border-radius: 5px;
    color: #4ECFDB;
}
.el-select-dropdown__item{
    color: rgba(78, 207, 219, 0.7);
    font-size: 24px;
}


.el-popper[x-placement^=bottom] .popper__arrow::after{
    border-bottom-color: transparent;
}
.el-popper .popper__arrow, .el-popper .popper__arrow::after{
    border-style: none !important;
}
</style>
